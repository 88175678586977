@font-face {
	font-family: 'SolaimanLipi';
  font-weight: 400;
  font-style: normal;
	src: local('SolaimanLipi-Regular'), url('SolaimanLipi-Regular.ttf') format('truetype');
	}
	
	@font-face {
		font-family: 'SolaimanLipi';
		font-weight: 700;
		font-style: normal;
		src: local('SolaimanLipi-Bold'), url('SolaimanLipi-Bold.ttf') format('truetype');
		}